<template>
  <v-app id="inspire">
    <v-snackbar v-model="snackbar">
      <v-icon class="primary--text pa-1">mdi-alert</v-icon>
      <span class="white--text"
        >Mauvais e-mail ou mot de passe. Veuillez réessayer.</span
      >
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col style="margin-top: -100px" cols="12" sm="8" md="4">
          <v-card class="opa elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Authentification Manager Korian</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon large target="_blank" v-on="on">
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Se connecter</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="valid" ref="form" :lazy-validation="false">
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  name="Email"
                  :rules="emailRules"
                  prepend-icon="mdi-email"
                  type="email"
                ></v-text-field>
                <v-text-field
                  id="password"
                  :rules="nameRules"
                  v-model="password"
                  label="Mot de passe"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  @keyup.enter="login"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center center">
              <v-btn
                type="submit"
                :disabled="!valid"
                :loading="loading"
                @click="login"
                color="primary"
              >
                <v-icon>mdi-lock</v-icon>Se connecter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>


<script>
import xhr from "../plugins/axios";

export default {
  name: "Login",
  data() {
    return {
      snackbar: false,
      loading: false,
      valid: true,
      email: "",
      password: "",
      nameRules: [
        (v) => !!v || "Mot de passe obligatoire",
        (v) =>
          (v && v.length >= 8) ||
          "Mot de passe doit faire au moins 8 caractères",
      ],
      emailRules: [
        (v) => !!v || "E-mail obligatoire",
        (v) => /.+@.+\..+/.test(v) || "E-mail doit être valide",
      ],
    };
  },
  methods: {
    login() {
      this.loading = true;
      xhr
        .post("/login", { email: this.email, password: this.password })
        .then((res) => {
          if (!res.data || res.data === null) {
            this.snackbar = true;
            this.loading = false;
          } else {

            console.log(res.data, "res.data");
            xhr
              .post("/loadRooms", {
                org: res.data.organisationId,
                prop: res.data.property,
                mode: res.data.isLiving,
              })
              .then((resultat) => {
                localStorage.setItem(
                  "prop",
                  JSON.stringify(resultat.data.prop)
                );
                localStorage.setItem(
                  "rooms",
                  JSON.stringify(resultat.data.rooms)
                );
                this.loading = false;

              localStorage.setItem("etbId", res.data.etbId);
              localStorage.setItem("id", res.data._id);
              localStorage.setItem("email", res.data.email);
              localStorage.setItem("user", JSON.stringify(res.data));

                // localStorage.setItem("name", res.data.prop.name);
                if (this.admin === true) {
                  window.location = "/add";
                } else {
                  window.location = "/";
                }
              });
    
            //
          }
        });
    },
  },
};
</script>